import React from "react"

import Layout from "../components/layout"
import { Seo } from "../components/seo"
import Home from "../components/home"

const IndexPage = () => (
  <Layout>
    <Home />
  </Layout>
)

export default IndexPage

export const Head = () => <Seo title="Home | Universal Game Protection" />
