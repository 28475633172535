import React, { useState, useEffect } from "react"
import { videoCaption, container, copy } from "./styles.module.less"

const Home = () => {
  if (typeof window === "undefined") {
    global.window = {}
  }

  const [windowWidth, setWindowWidth] = useState(600)

  let videoWidth
  let videoHeight

  useEffect(() => {
    if (window.innerWidth && window.innerWidth > 900) {
      setWindowWidth(900)
    } else {
      setWindowWidth(window.innerWidth)
    }
  }, [windowWidth])

  if (windowWidth >= 768) {
    videoWidth = windowWidth * 0.95
  }
  if (windowWidth < 768) {
    videoWidth = windowWidth * 0.85
  }

  videoHeight = videoWidth * 0.5625

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <iframe
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube-nocookie.com/embed/ki4Fbctwnjs"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ marginBottom: "8px" }}
        ></iframe>
        <div
          style={{ width: videoWidth, textAlign: "left" }}
          className={videoCaption}
        >
          <p>
            Casino Cheating Expert Reviews Card Counting and Casino Scams From
            Movies | Vanity Fair
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "32px",
        }}
      >
        <iframe
          width={videoWidth}
          height={videoHeight}
          src="https://www.youtube-nocookie.com/embed/Pb-scm2ASNM"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube video player"
          style={{ marginBottom: "8px" }}
        ></iframe>
        <div
          style={{ width: videoWidth, textAlign: "left" }}
          className={videoCaption}
        >
          <p>Meet Sal Piacente, president of UniverSal Game Protection, Inc.</p>
        </div>
      </div>

      <div className={container}>
        <div className={copy}>
          <h1>Welcome</h1>
          <h3>What is "Game Protection?"</h3>
          <p>Game Protection is:</p>
          <blockquote>
            the art of safeguarding the table games
            <br />
            <cite>
              Casino Game Protection: A Comprehensive Guide by Steve Forte
            </cite>
          </blockquote>
          <br />
          <p>
            It involves both loss prevention and detection of scams and
            advantage plays in the casino.
          </p>

          <p>
            UniverSal Game Protection Development, Inc. offers a range of
            customized game protection services to suit the needs of casino
            table games departments, surveillance departments, and gaming
            regulators. We consider the particular staff, procedures, rules, and
            equipment, and create programs tailored to the needs of our clients.
          </p>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Home
